<template>
  <div>
    <router-link :to="{ name: 'admin.categories.create' }" class="btn btn-primary btn-sm float-right px-2 py-2"><i class="lni lni-plus mr-2"></i> Add New</router-link>
    <h4>Categories</h4>
    <div class="card">
      <div class="card-body">
        <div class="row justify-content-end">
          <div class="col-md-4">
            <input type="text" placeholder="Search" class="form-control" v-model="search_term">
          </div>
        </div>
        <table class="table table-responsive table-borderless">
          <tbody>
            <tr v-for="(category, i) in categories" :key="`category-${i}`">
              <td width="20px">{{ i+1 }}</td>
              <td>
                <p :class="loading ? 'skeleton skeleton-text' : ''"><router-link :to="{ name: 'admin.categories.show', params: { id: category.id }}">{{ category.name }}</router-link></p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch()
  },

  data() {
    return {
      all_categories: [],
      loading: true,
      search_term: ''
    }
  },

  methods: {
    fetch() {
      this.$axios.get('/api/v1/admin/categories').then(response => {
        this.all_categories = response.data.categories
        this.loading = false
      })
    }
  },

  computed: {
    categories() {
      return this.all_categories.filter(category => {
        return category.name.toUpperCase().search(this.search_term.toUpperCase()) >= 0
      })
    }
  }
}
</script>